<template>
  <v-container class="general">
    <PageTitle :title="'Group policies'" />
    <v-layout class="row wrap">
      <v-flex class="xs12">
        <v-card class="pa-4">
          <v-layout class="row wrap align-center">
            <v-flex class="xs12 mb-1">
              <span class="sw-primary font-weight-medium">{{
                policy.description
              }}</span></v-flex
            >
            <v-flex class="xs12 mb-3">
              <v-divider class="primary"></v-divider>
            </v-flex>
            <v-flex v-for="(role, i) in roles" :key="`role-${i}`" class="xs12">
              <v-btn
                @click="setPolicyRole(role.id)"
                icon
                class="ml-0 sw-accent-bg white--text"
                ><v-icon small>{{
                  isRoleSelected(role.id) ? "check" : "radio_button_unchecked"
                }}</v-icon></v-btn
              >{{ role.description }}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex class="xs12 text-xs-center pt-4">
        <v-btn
          @click="$router.push({ name: 'group_policies' })"
          round
          class="text-none"
          >{{ $t("back") }}</v-btn
        >
        <v-btn
          @click="updateGroupPolicy"
          :loading="loading"
          round
          class="sw-accent-bg sw-on-accent text-none"
          >{{ $t("save") }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    policy: {},
    roles: [],
    perPage: 100,
  }),
  computed: {
    policyId() {
      return this.$route.params.policy_id;
    },
  },
  mounted() {
    this.getGroupPolicy();
    this.listGroupRoles();
  },
  methods: {
    getGroupPolicy() {
      this.$api.groupPolicies.get(this.policyId).then((response) => {
        if (!response) return;

        this.policy = {
          id: response.data.data.id,
          description: response.data.data.description,
          group_roles: response.data.data.group_roles.map((el) => el.id),
        };
      });
    },
    listGroupRoles() {
      this.$api.groupRoles.list({ per_page: this.perPage }).then((response) => {
        if (!response) return;
        this.roles = response.data.data;
      });
    },
    updateGroupPolicy() {
      const specs = [
        this.policy.id,
        { group_roles: this.policy.group_roles.toString() },
      ];

      this.loading = true;

      this.$api.groupPolicies.update(...specs).then(
        (response) => {
          this.loading = false;

          if (!response) return;
          this.$store.dispatch("addNotification", {
            message: "Group policy updated",
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    setPolicyRole(id) {
      if (!id) return;

      if (!this.policy.group_roles.includes(id)) {
        this.policy.group_roles.push(id);
        return;
      }

      this.policy.group_roles = this.policy.group_roles.filter(
        (el) => el !== id,
      );
    },
    isRoleSelected(roleId) {
      if (!roleId) return;
      if (!this.policy.group_roles) return;

      return this.policy.group_roles.includes(roleId);
    },
  },
};
</script>

<style scoped lang="scss"></style>
